<template>
  <div>
    <h1>Prüfung aus Log erneut hochladen</h1>

    <audit-form
      v-if="formData"
      :default-form-data="formData"
      @submit="submit"
    />
    <div v-else class="flex items-center justify-center h-20">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import AuditForm from '@/components/AuditForm'
import store from '@/store'
import api from '@/api'
import Loading from '@/components/Loading'

export default {
  name: 'QueueEdit.vue',
  components: { Loading, AuditForm },
  data: () => ({
    request: Object,
    formData: null,
    submitDisabled: false
  }),
  computed: {},
  methods: {
    submit (data) {
      this.submitDisabled = true

      const identifier = Math.random().toString(36).substr(2, 9)

      const requestData = {
        ...this.request,
        body: JSON.stringify({ ...data, identifier: identifier }),
        headers: {
          'Authorization': 'Bearer ' + store.state.user.accessToken,
          'Content-Type': 'application/json',
        },
      }

      const request = new Request(this.request.url, requestData)

      fetch(request)
        .then(response => response.json())
        .then(response => {
          this.submitDisabled = false
          if (response.success) {
            this.$emit('alert', 'success', 'Änderungen hochgeladen')
            this.$router.push({ name: 'Log' })
          } else {
            this.$emit('alert', 'danger', Object.values(response.errors).join(' '))
          }
        })
        .catch(async error => {
          this.submitDisabled = false

          const queue = await this.$workbox.messageSW({ type: 'GET_QUEUE' })
          if (queue.find(request => request?.metadata?.request?.identifier === identifier)) {
            this.$emit('alert', 'success', 'Änderung wurde in der Warteschlange gespeichert')
            this.$router.push({ name: 'Log' })
          } else {
            this.$emit('alert', 'danger', 'Änderung konnte nicht in der Warteschlange gespeichert werden. Stellen Sie eine Internetverbindung her und versuchen Sie es erneut.')
          }

          console.error(error)
        })
    },
  },
  created () {
    const request = store.state.requestLog[this.$route.params.index]
    this.$set(this, 'request', request)
    this.$set(this, 'formData', JSON.parse(request.body))
  },
  mixins: [api]
}
</script>

<style scoped>

</style>
