<template>
  <form
      @submit.prevent
      class="w-full relative h-auto my-4 mb-14"
  >
    <div v-if="page === 'general'">
      <h2 class="my-4">Allgemeine Daten</h2>

      <div class="pb-4 text-right">
        <t-button @click="openPrefillModal" variant="secondary" type="button">
          <fa icon="barcode"></fa>
          Vorfüllen aus alter Prüfung
        </t-button>
      </div>

      <t-input-group label="Prüfungsnummer (Pflichtfeld)">
        <t-input v-model.lazy="formData.number" required @change="emitChange"
                 @input="checkIfAuditNumberExists"/>
        <fa @click="clearFormData('number')"
            icon="times-circle"
            class="suffix-icon"
            style="width: 2.25rem"></fa>
      </t-input-group>

      <p v-if="numberExists" class="text-danger font-bold mb-4">Prüfnummer existiert bereits!</p>

      <t-input-group
          label="Planung"
      >
        <t-rich-select
            :options="scheduleSelectOptions"
            searchBoxPlaceholder="Suchen..."
            noResultsText="Keine Optionen verfügbar"
            maxHeight="400"
            :value="scheduleValue"
            @change="setSchedule"
        >
        </t-rich-select>
        <fa @click="clearFormData('schedule')"
            icon="times-circle"
            class="suffix-icon right-8"
            style="width: 2.25rem"></fa>
      </t-input-group>

      <t-input-group
          label="Kunde"
      >
        <t-rich-select
            :options="customerSelectOptions"
            searchBoxPlaceholder="Suchen..."
            noResultsText="Keine Optionen verfügbar"
            maxHeight="400"
            :value="customerValue"
            @change="setCustomer"
        >
        </t-rich-select>
        <fa @click="clearFormData('customer')"
            icon="times-circle"
            class="suffix-icon right-8"
            style="width: 2.25rem"></fa>
      </t-input-group>

      <t-input-group
          label="Gerät"
      >
        <t-rich-select
            :options="equipmentSelectOptions"
            searchBoxPlaceholder="Suchen..."
            noResultsText="Keine Optionen verfügbar"
            maxHeight="400"
            :value="equipmentValue"
            @change="setEquipment"
        >
        </t-rich-select>
        <fa @click="clearFormData('equipment')"
            icon="times-circle"
            class="suffix-icon right-8"
            style="width: 2.25rem"></fa>
      </t-input-group>

      <t-input-group label="Prüfungsdatum">
        <t-input
            v-model.lazy="formData.date"
            @change="emitChange"
            type="date"
        />
      </t-input-group>

      <t-input-group
          label="Inventarnummer"
      >
        <t-input
            v-model.lazy="formData.inventory_number"
            @change="emitChange"
        />
        <fa @click="clearFormData('inventory_number')"
            icon="times-circle"
            class="suffix-icon"
            style="width: 2.25rem"></fa>
      </t-input-group>

      <t-input-group
          label="Liegenschaft"
      >
        <t-input
            v-model.lazy="formData.location"
            @change="emitChange"
        />
        <fa @click="clearFormData('location')"
            icon="times-circle"
            class="suffix-icon"
            style="width: 2.25rem"></fa>
      </t-input-group>

      <t-input-group
          label="Etage"
      >
        <t-input
            v-model.lazy="formData.level"
            @change="emitChange"
        />
        <fa @click="clearFormData('level')"
            icon="times-circle"
            class="suffix-icon"
            style="width: 2.25rem"></fa>
      </t-input-group>

      <t-input-group
          label="Raum-Nr."
      >
        <t-input
            v-model.lazy="formData.room"
            @change="emitChange"
        />
        <fa @click="clearFormData('room')"
            icon="times-circle"
            class="suffix-icon"
            style="width: 2.25rem"></fa>
      </t-input-group>

      <t-input-group
          label="Geräteart"
      >
        <t-input
            v-model.lazy="formData.device_type"
            @change="emitChange"
        />
        <fa @click="clearFormData('device_type')"
            icon="times-circle"
            class="suffix-icon"
            style="width: 2.25rem"></fa>
      </t-input-group>

      <t-input-group
          label="Hersteller"
          class="border-t pt-4 border-gray"
      >
        <t-input
            v-model.lazy="formData.manufacturer"
            @change="emitChange"
        />
        <fa @click="clearFormData('manufacturer')"
            icon="times-circle"
            class="suffix-icon"
            style="width: 2.25rem"></fa>
      </t-input-group>

      <div class="flex gap-3">
        <t-input-group
            label="Typ"
            class="flex-grow"
        >
          <t-input
              v-model.lazy="formData.device_model"
              @change="emitChange"
          />
          <fa @click="clearFormData('device_model')"
              icon="times-circle"
              class="suffix-icon"
              style="width: 2.25rem"></fa>
        </t-input-group>

        <t-input-group
            label="Wärmegerät"
        >
          <t-checkbox
              style="height: 2.5rem; width: 2.5rem"
              v-model.lazy="formData.warming_device"
              @change="emitChange"
          />
        </t-input-group>
      </div>

      <div class="pb-4 text-right border-b border-gray">
        <t-button @click="setNotRecognizable" variant="secondary" type="button">
          <fa icon="question"></fa>
          Nicht erkennbar
        </t-button>
      </div>

      <t-input-group
          label="Freifeld"
      >
        <t-input
            v-model.lazy="formData.free_text"
            @change="emitChange"
        />
        <fa @click="clearFormData('free_text')"
            icon="times-circle"
            class="suffix-icon"
            style="width: 2.25rem"></fa>
      </t-input-group>

      <t-input-group
          label="Art der Prüfung"
      >
        <t-rich-select
            :options="checklistSelectOptions"
            searchBoxPlaceholder="Suchen..."
            noResultsText="Keine Optionen verfügbar"
            maxHeight="400"
            :value="typeValue"
            @change="setChecklist"
            variant="up"
        >
        </t-rich-select>
        <span class="text-danger"
              v-if="currentChecklist && currentChecklist.length > 0 && !currentChecklist.checklist">
          Prüfliste unvollständig. Bitte die Prüfliste erneut vom Server herunterladen.
        </span>
      </t-input-group>

      <div class="flex flex-wrap md:flex-nowrap mb-4 text-right">
        <div class="w-full mt-4 md:mt-0 text-left">
          <t-button @click="reset" variant="secondary" type="button">
            <fa icon="redo"></fa>
            Zurücketzen
          </t-button>
        </div>
        <div class="w-full mt-4 md:mt-0">
          <t-button :disabled="!hasAuditType || !hasChecklist" @click="startAuditProcess" type="button">
            <fa icon="clipboard-list"></fa>
            Prüfprozess starten
          </t-button>
        </div>
      </div>
    </div>

    <div v-else-if="page === 'checklist'">
      <div class="flex justify-between flex-wrap md:flex-nowrap mb-4 text-right">
        <div class="mt-4 md:mt-0 text-left">
          <t-button @click="page = 'general'" variant="secondary" type="button">
            Zurück
          </t-button>
        </div>
        <div class="mt-4 md:mt-0">
          <t-button @click="page = 'final'" type="button">
            Prüfprozess abschließen
          </t-button>
        </div>
      </div>

      <div class="fixed bottom-0 left-0 bg-white shadow w-screen z-10 text-center border-t-2 border-gray-lighter"
           v-if="formData.checklist">
        <div class="flex my-4 justify-evenly items-center">
          <div>
            <fa class="cursor-pointer" @click="currentChecklistStepNumber--"
                :class="{'pointer-events-none opacity-50': currentChecklistStepNumber <= 0}"
                icon="arrow-left"></fa>
          </div>
          <span>
              Schritt {{ currentChecklistStepNumber + 1 }} von {{ formData.checklist.length }}
          </span>
          <div>
            <fa class="cursor-pointer" @click="currentChecklistStepNumber++"
                :class="{'pointer-events-none opacity-50': currentChecklistStepNumber >= formData.checklist.length - 1}"
                icon="arrow-right"></fa>
          </div>
        </div>

        <div class="w-full h-2 bg-gray-lighter">
          <div :style="{width: ((currentChecklistStepNumber + 1) / formData.checklist.length * 100) + '%'}"
               class="bg-brand h-full transition-all duration-500" style="max-width: 98%">
          </div>
        </div>
      </div>

      <div class="slider-wrap overflow-hidden min-h-screen mb-20"
           :style="{height: sliderHeight + 'px'}"
      >
        <div class="slider-track transition-all duration-500"
             :style="{transform: 'translateX(' + -100 * currentChecklistStepNumber + '%)'}"
        >
          <div v-for="(checklistStep, checklistStepIndex) in formData.checklist"
               :key="checklistStepIndex"
               class="slider-item absolute w-full top-0 p-4"
               :style="{left: (100 * checklistStepIndex) + '%'}"
          >
            <div
                class="text-gray transition float-right flex justify-end items-center cursor-pointer hover:text-gray-dark"
                @click="removeChecklistStep(checklistStepIndex)">
              <fa icon="times" class="mr-2"></fa>
              Prüfschritt entfernen
            </div>

            <p class="text-gray-darkest">
              {{ checklistStep && checklistStep.function ? checklistStep.function.brezel_name : '' }}</p>
            <h2 class="mb-4"> {{ checklistStep.step }} </h2>


            <p v-if="checklistStep.criterion" class="mb-4"><b>Kriterium: {{ checklistStep.criterion }}</b>
            </p>

            <t-input-group label="Ergebnis">
              <div class="flex items-center">
                <t-input
                    v-model.lazy="formData.checklist[checklistStepIndex].result"
                >
                </t-input>

                <div v-if="checklistStep.unit" class="ml-4">
                  {{ checklistStep.unit }}
                </div>
              </div>

            </t-input-group>

            <t-input-group label="Regiezeit in Minuten">
              <t-input
                  type="number"
                  v-model.lazy="formData.checklist[checklistStepIndex].cost_plus"
              >
              </t-input>
            </t-input-group>

            <t-input-group class="text-center" label="OK">
              <t-checkbox
                  v-model.lazy="formData.checklist[checklistStepIndex].check"
                  @click="ev => ev.target.checked ? goToNextStep() : null"
              />
            </t-input-group>

            <div v-if="formData.checklist[checklistStepIndex].file" class="mb-4 overflow-y-auto h-1/2">
              <h2 v-if="formData.checklist[checklistStepIndex].file.length > 0" class="mb-4">Dateien</h2>

              <div v-for="(file, fileIndex) in formData.checklist[checklistStepIndex].file"
                   :key="fileIndex"
                   class="border-t border-gray-light py-3">
                <img v-if="file.mime_type.includes('image/')" alt="Hochgeladenes Bild" :src="file.__url"
                     class="mx-auto max-w-full">

                <a v-else class="transition text-info-dark hover:text-info" href="#"
                   @click="showFile(file)">{{ file.name }} - Anzeigen</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="page === 'final'">
      <div class="flex justify-between flex-wrap md:flex-nowrap mb-4 text-right">
        <div class="mt-4 md:mt-0 text-left">
          <t-button @click="page = 'checklist'" variant="secondary" type="button">
            Zurück
          </t-button>
        </div>
      </div>

      <h2 class="mb-4">Zusammenfassung</h2>

      <table class="divide-y divide-gray-light">
        <tbody>
        <tr>
          <td class="">Prüfungsnummer:</td>
          <td class="p-1 px-2 text-left">{{ formData.number }}</td>
        </tr>
        <tr>
          <td class="">Prüfungsdatum:</td>
          <td class="p-1 px-2 text-left">{{ humanReadableDate }}</td>
        </tr>
        </tbody>
      </table>

      <div class="my-6 border-t border-gray-light"></div>

      <p class="mb-2">Prüfungsergebnis</p>
      <div class="overflow-x-auto">
        <table class="text-sm min-w-full divide-y divide-gray-light">
          <thead>
          <tr>
            <th class="p-1 px-2">Prüfschritt</th>
            <th class="p-1 px-2">OK</th>
            <th class="p-1 px-2">Ergebnis</th>
            <th class="p-1 px-2">Regiezeit</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(step, stepIndex) in formData.checklist"
              :key="stepIndex"
              :class="{'bg-gray-lightest': stepIndex % 2 === 1}"
          >
            <td class="p-1 px-2" style="min-width: 220px">{{ step.step }}</td>
            <td class="p-1 px-2 text-center">
              <fa class="text-success" v-if="step.check" icon="check"></fa>
            </td>
            <td class="p-1 px-2">{{ step.result }}</td>
            <td class="p-1 px-2">{{ step.cost_plus }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <p class="mb-4 mt-2 pt-2 text-center">
        <span v-if="allStepsOk" class="text-xl font-bold text-success">Alle Prüfschritte OK<br></span>
        <span v-if="!allStepsOk" class="text-xl font-bold text-danger">Nicht alle Prüfschritte OK<br></span>
        Regiezeit gesamt: <b>{{ getTotalCostPlusTime() }}
        Minuten</b>
      </p>

      <div class="my-6 border-t border-gray-light"></div>

      <t-input-group label="Dateien hochladen">
        <t-input
            type="file"
            multiple
            @change="uploadFiles"
        />
        <div class="" v-for="(file, fileIndex) in formData.files" :key="fileIndex">
          <div class="flex justify-between items-center py-2 text-black">
            <fa class="text-brand" icon="paperclip"></fa>
            <p class="w-full" @click="showFile(file)">{{ file.name }}</p>
            <span v-if="!file.id" class="w-full text-danger-dark">Nicht hochgeladen</span>
            <fa icon="times" @click="removeFile(fileIndex)"></fa>
          </div>
        </div>
      </t-input-group>

      <t-input-group label="Nächste Prüfung">
        <t-input
            type="date"
            v-model.lazy="formData.next_audit"
            @change="emitChange"
        />
      </t-input-group>

      <div>
        <p>Unterschrift</p>
        <div class="relative" v-if="!signature || editSignature">
          <t-button
              v-if="signature"
              @click="editSignature = false; setPresetSignature()"
              variant="secondary"
              class="absolute right-14 top-0"
              type="button"
          >
            <fa icon="times" class="mr-0"></fa>
          </t-button>
          <t-button @click="undoSignature"
                    variant="secondary"
                    class="absolute right-0 top-0"
                    type="button"
          >
            <fa icon="redo"></fa>
          </t-button>
          <vue-signature-pad
              width="28rem"
              height="15rem"
              ref="signaturePad"
              :options="{ onEnd, onBegin: () => {$refs.signaturePad.resizeCanvas()}, throttle: 32 }"
              class="max-w-full mx-auto border border-gray-light"
          />
        </div>
        <div class="relative" v-else>
          <t-button @click="editSignature = true; $set(formData, 'signature', null)"
                    class="absolute right-0 top-0"
                    variant="secondary"
                    type="button"
          >
            <fa :icon="['far', 'edit']"></fa>
          </t-button>
          <img :src="signature" alt="signature">
        </div>
      </div>

      <t-button type="submit"
                class="my-4 w-full"
                @click="submit"
                :disabled="!canSave"
      >
        <fa :icon="['far', 'save']"></fa>
        Prüfung abschließen
      </t-button>
    </div>

    <t-modal v-if="showModal" v-model="showModal">
      <div class="text-center">
        <img :src="image" alt="Datei" class="mx-auto my-6 max-w-full">
      </div>
    </t-modal>

    <t-modal v-if="showPrefillModal" v-model="showPrefillModal">
      <div class="text-center">
        <t-input-group
            label="Vorherige Prüfung"
        >
          <t-rich-select
              :fetch-options="getAuditSelectOptions"
              search-box-placeholder="Suchen..."
              searchingText="Prüfungen werden durchsucht..."
              noResultsText="Keine Optionen verfügbar"
              maxHeight="400"
              :value="auditValue"
              @change="setAudit"
          >
          </t-rich-select>
          <fa @click="clearFormData('previous_audit')"
              icon="times-circle"
              class="suffix-icon right-8"
              style="width: 2.25rem"></fa>
        </t-input-group>
      </div>
    </t-modal>
  </form>
</template>

<script>
import store from '@/store'
import dayjs from 'dayjs'
import api from '@/api'
import _ from "lodash";

export default {
  name: 'Form',
  props: {
    defaultFormData: Object,
    submitDisabled: Boolean,
  },
  data() {
    return {
      auditSelectOptionsQuery: '',
      numberExists: false,
      editSignature: false,
      formData: _.cloneDeep(this.defaultFormData),
      page: 'general',
      currentChecklistStepNumber: null,
      image: null,
      showModal: false,
      showPrefillModal: false,
      sliderHeight: 0
    }
  },
  computed: {
    allStepsOk() {
      return this.formData.checklist.reduce((isCorrect, step) => step.check ? isCorrect : false, true)
    },
    canSave() {
      return this.formData.number && this.formData.signature && !this.submitDisabled
    },
    audits() {
      return store.state.modules.audits
    },
    auditNumbers() {
      return store.state.auditNumbers
    },
    checklists() {
      return store.state.modules.checklists
    },
    customers() {
      return store.state.modules.customers
    },
    equipment() {
      return store.state.modules.equipment
    },
    schedules() {
      return store.state.modules.schedules
    },
    allAuditSelectOptions() {
      if (typeof this.audits !== 'object') {
        return []
      }
      return Object.values(this.audits).map(item => ({
        id: item.id,
        label: item.brezel_name,
      }))
    },
    auditSelectOptions() {
      let options = this.allAuditSelectOptions
      if (this.auditSelectOptionsQuery) {
        options = options.filter(it => it.label.search(this.auditSelectOptionsQuery) !== -1)
      }
      return options.slice(0, 15)
    },
    auditValue() {
      const audit = this.formData.previous_audit
      return audit ? JSON.stringify({id: audit.id, module_id: audit.module_id}) : null
    },
    checklistSelectOptions() {
      return this.checklists.map(item => ({
        id: JSON.stringify({id: item.id, module_id: item.module_id}),
        label: item.brezel_name,
      }))
    },
    customerValue() {
      const customer = this.formData.customer
      return customer ? JSON.stringify({id: customer.id, module_id: customer.module_id}) : null
    },
    currentChecklist() {
      if (this.hasAuditType) {
        return this.checklists.find(checklist => checklist.id === this.formData.type.id)
      }
      return []
    },
    customerSelectOptions() {
      if (!Array.isArray(this.customers)) {
        return []
      }
      return this.customers.map(item => ({
        id: JSON.stringify({id: item.id, module_id: item.module_id}),
        label: item.brezel_name,
      }))
    },
    equipmentSelectOptions() {
      if (!Array.isArray(this.equipment)) {
        return []
      }
      return this.equipment.map(item => ({
        id: JSON.stringify({id: item.id, module_id: item.module_id}),
        label: item.brezel_name,
      }))
    },
    equipmentValue() {
      const equipment = this.formData.equipment
      return equipment ? JSON.stringify({id: equipment.id, module_id: equipment.module_id}) : null
    },
    hasChecklist() {
      return !!this.formData.checklist
    },
    hasAuditType() {
      return !!this.formData.type
    },
    humanReadableDate() {
      return dayjs(this.formData.date).format("DD.MM.YYYY")
    },
    scheduleSelectOptions() {
      if (!Array.isArray(this.schedules)) {
        return []
      }
      return this.schedules.map(item => ({
        id: JSON.stringify({id: item.id, module_id: item.module_id}),
        label: item.brezel_name,
      }))
    },
    scheduleValue() {
      const schedule = this.formData.schedule
      return schedule ? JSON.stringify({id: schedule.id, module_id: schedule.module_id}) : null
    },
    signature() {
      return store.state.signature
    },
    typeValue() {
      const type = this.formData.type
      return type ? JSON.stringify({id: type.id, module_id: type.module_id}) : null
    },
    online() {
      return store.state.online
    },
  },
  methods: {
    calculateSliderHeight() {
      this.sliderHeight = Math.max(
          ...Array.from(document.querySelectorAll('.slider-item'))
              .map(item => item.clientHeight)
      )
    },
    checkIfAuditNumberExists(value) {
      if (
          this.auditNumbers && Array.isArray(this.auditNumbers)
      ) {
        this.numberExists = this.auditNumbers.indexOf(value ?? this.formData.number) !== -1 && this.formData.number !== this.defaultFormData.number
      }
    },
    clearFormData(key) {
      this.$set(this.formData, key, '')
    },
    async fetchAuditByNumber(number) {
      const res = await this.fetchResourcesByModuleIdentifier(
          'audits',
          {
            results: 1, columns: ['id'], pre_filters: JSON.stringify([{
              column: "number", operator: "=", value: number
            }])
          }
      )
      if (res.data && res.data[0]) {
        const audit = await this.fetchResourcesDetail('audits', [res.data[0]])
        return audit[0]
      }
      return null
    },
    async getAuditSelectOptions (q) {
      this.auditSelectOptionsQuery = q
      return new Promise(resolve => {resolve({results: this.auditSelectOptions })})
    },
    getTotalCostPlusTime() {
      let accumulator = 0
      this.formData.checklist.forEach(step => {
        accumulator += parseInt(step.cost_plus || 0)
      })
      this.$set(this.formData, 'total_cost_plus', accumulator)
      return accumulator
    },
    setFileUrls() {
      if (!this.formData.checklist || this.formData.checklist.length > 0 || !this.formData.checklist[this.currentChecklistStepNumber].file) return
      this.formData.checklist[this.currentChecklistStepNumber].file.forEach(
          async (file, fileIndex) => {
            if (file.__url) return
            const url = await this.getFileUrl(file)
            this.$set(this.formData.checklist[this.currentChecklistStepNumber].file[fileIndex], '__url', url)
          }
      )
    },
    goToNextStep() {
      if (this.currentChecklistStepNumber < this.formData.checklist.length - 1) {
        this.currentChecklistStepNumber++
      }
    },
    handleCheck(ev) {
      this.formData.checklist[this.currentChecklistStepNumber].check = ev.target.checked
      if (ev.target.checked) this.goToNextStep()
    },
    onEnd() {
      const {data} = this.$refs.signaturePad.saveSignature()
      this.$set(this.formData, 'signature', data)
      this.$refs.signaturePad.fromDataURL(data)
    },
    removeChecklistStep(checklistStep) {
      if (!window.confirm('Dieser Prüfschritt wird entfernt und Ihre eingegeben Daten gehen verloren. Wollen Sie fortfahren?')) {
        return
      }
      let checklist = this.formData.checklist
      if (this.currentChecklistStepNumber >= checklist.length - 1) {
        this.currentChecklistStepNumber--
      }
      checklist.splice(checklistStep, 1)
      this.$set(this.formData, 'checklist', checklist)
    },
    setAudit: async function (value) {
      this.showPrefillModal = false
      if (value) {
        const audit = this.audits[value]
        if (!audit) {
          alert("Prüfungsdaten nicht in Speicher vorhanden.")
          return
        }
        if (!window.confirm("Daten aus der vorherigen Prüfung ausfüllen?")) return
        const allowed = ['customer', 'employee_tmp', 'customer', 'equipment', 'inventory_number',
          'location', 'level', 'room', 'device_type', 'manufacturer', 'device_model', 'warming_device', 'type'
        ]
        this.$set(this.formData, 'previous_audit', audit)
        this.setChecklist(JSON.stringify(audit.type))

        Object.keys({...audit})
            .forEach(key => {
              if (allowed.includes(key)) {
                this.$set(this.formData, key, audit[key])
              }
            })
      }
      this.showPrefillModal = false
    },
    setChecklist(value) {
      const type = JSON.parse(value)
      if (this.formData.type && this.formData.type.id === type.id) {
        return
      }
      if (this.hasAuditType) {
        if (!window.confirm('Prüfliste wird überschrieben. Wollen Sie fortfahren?')) {
          return
        }
      }
      this.$set(this.formData, 'type', type)
      if (this.currentChecklist.checklist) {
        this.$set(this.formData, 'checklist',
            this?.currentChecklist?.checklist.map(checklist => {
              let data = {...checklist}
              delete data.id
              delete data.resource_id
              return data
            })
        )
      }

      this.calculateSliderHeight()
      this.emitChange()
      this.$forceUpdate()
    },
    setCustomer(value) {
      this.$set(this.formData, 'customer', JSON.parse(value))
    },
    setEquipment(value) {
      this.$set(this.formData, 'equipment', JSON.parse(value))
    },
    setNotRecognizable() {
      this.$set(this.formData, 'manufacturer', 'n.e.')
      this.$set(this.formData, 'device_model', 'n.e.')
      this.emitChange()
    },
    setSchedule(value) {
      this.$set(this.formData, 'schedule', JSON.parse(value))
    },
    async showFile(file) {
      const url = await this.getFileUrl(file)
      if (file.mime_type.includes('image/')) {
        this.image = url
        this.showModal = true
      } else {
        window.open(url)
      }
    },
    openPrefillModal() {
      this.showPrefillModal = true
    },
    startAuditProcess() {
      this.currentChecklistStepNumber = 0
      this.page = 'checklist'
      this.$nextTick(() => this.calculateSliderHeight())
    },
    undoSignature() {
      this.$refs.signaturePad.undoSignature()
      const {data} = this.$refs.signaturePad.saveSignature()
      this.$set(this.formData, 'signature', data)
      this.$refs.signaturePad.fromDataURL(data)
    },
    uploadFiles(e) {
      const files = e.target.files
      for (let i = 0; i < files.length; i++) {
        let file = files[i]
        let data = new FormData()
        // An identifier to search for this file later in request queue
        const identifier = Math.random().toString(36).substr(2, 9)
        data.append('file', file)
        data.append('module', 'files')
        data.append('identifier', identifier)
        const requestData = {
          method: 'POST',
          body: data,
          headers: {
            'Authorization': 'Bearer ' + store.state.user.accessToken,
          }
        }
        const request = new Request(store.state.apiUrl + '/files', requestData)

        fetch(request)
            .then(response => {
              return response.json()
            })
            .then(response => {
              if (response.success) {
                let files = this.formData.files || []
                files.push(response.data)
                this.$set(this.formData, 'files', files)
                this.emitChange()
              }
            })
            .catch(error => {
              let formFiles = this.formData.files || []
              file.requestIdentifier = identifier
              formFiles.push(file)
              this.$set(this.formData, 'files', formFiles)
              this.emitChange()
              console.error(error)
            })
      }
    },
    removeFile(fileIndex) {
      let formFiles = this.formData.files
      formFiles.splice(fileIndex, 1)
      this.$set(this.formData, 'files', formFiles)
    },
    reset() {
      this.$set(this, 'formData', {})
    },
    setPresetSignature () {
      if (this.signature) {
        this.$set(this.formData, 'signature', this.signature)
      }
    },
    submit() {
      if (!this.formData.end_time) {
        this.$set(this.formData, 'end_time', new Date().toUTCString())
      }
      this.$emit('submit', this.formData)
    },
    emitChange() {
      if (!this.formData.start_time) {
        this.formData.start_time = new Date().toUTCString()
      }
      this.$emit('change', this.formData)
    }
  },
  created() {
    this.checkIfAuditNumberExists()

    this.calculateSliderHeight()

    this.setPresetSignature()
  },
  watch: {
    currentChecklistStepNumber() {
      this.setFileUrls()
    },
    'defaultFormData': {
      deep: true,
      handler (value) {
        this.$set(this, 'formData', value)
        this.$forceUpdate()
      }
    },
    page() {
      window.scrollTo(0, 0)
    }
  },
  mixins: [api]
}
</script>

<style scoped>

</style>
