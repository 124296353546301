<template>
  <div>
    <div class="flex justify-between mb-4">
      <h1 class="">Warteschlange</h1>

      <t-button @click="getRequestsFromSW" variant="secondary" class="">
        <fa icon="redo"></fa>
        Neu laden
      </t-button>
    </div>

    <div v-if="requests.length > 0">
      <p class="text-gray-dark mb-4">Folgende Aktionen werden ausgeführt, sobald wieder eine Internetverbindung
        besteht:</p>

      <t-card v-for="request in auditRequests" :key="request.id" class="border-t-0">
        <div slot="header" class="flex justify-between">
          <div>
            <h1>{{ getActionString(request) }}</h1>

            <div class="max-w-2xl text-sm text-gray-darkest">
              <p class="mt-1">
              <span>
                <fa :icon="['far', 'calendar']"></fa>
                {{ getTimeString(request.timestamp) }}
              </span>
                <br>
                <span v-if="request.subRequests && request.subRequests.length > 0" class="mt-1">
                <fa icon="paperclip" class="mr-1"></fa>
                <span class="text-brand-dark">{{ request.subRequests.length }} Dateien</span>
              </span>
                <span v-if="request.metadata.errors" class="text-danger"><br>
                Änderung gescheitert. Grund: {{ request.metadata.errors }}
              </span>
              </p>
            </div>
          </div>
          <div class="flex flex-col justify-between">
            <router-link :to="'/queue/' + request.timestamp">
              <t-button style="padding: .25rem .5rem">
                <fa :icon="['far', 'edit']" class="mr-0"></fa>
              </t-button>
            </router-link>
            <div class="border-t border-gray-lighter my-2 w-3/4 mx-auto"></div>
            <t-button :disabled="request.loading" variant="secondary" style="padding: .25rem .5rem"
                      @click="retry(request)">
              <fa v-if="!request.loading" icon="redo" class="mr-0"></fa>
              <Loading spinner="bg-black" v-else></Loading>
            </t-button>
<!--            <div class="border-t border-gray-lighter my-2 w-3/4 mx-auto"></div>
            <t-button variant="secondary" style="padding: .25rem .5rem" @click="openModal(request)">
              <fa icon="code" class="mr-0"></fa>
            </t-button>-->
            <div class="border-t border-gray-lighter my-2 w-3/4 mx-auto"></div>
            <t-button variant="danger" style="padding: .25rem .5rem" @click="removeRequestAndSubRequests(request)">
              <fa v-if="!request.loading" :icon="['far', 'trash-alt']" class="mr-0"></fa>
              <Loading v-else spinner="bg-white"></Loading>
            </t-button>
          </div>
        </div>
      </t-card>
    </div>

    <p class="text-gray-dark mb-4" v-else>
      Noch sind keine Aktionen in der Warteschlange.
      Hier werden Aktionen hinzugefügt, die Sie in der App ausführen, wenn keine Serververbindung besteht.
    </p>

    <t-modal v-model="showModal">
      {{ modalData }}
    </t-modal>
  </div>
</template>

<script>
import api from '@/api'
import store from '@/store'
import Loading from '@/components/Loading'

export default {
  name: 'Edit.vue',
  components: {Loading},
  data: function () {
    return {
      requests: [],
      db: null,
      retryBlocked: {},
      showModal: false,
      modalData: null,
    }
  },
  computed: {
    auditRequests() {
      let fileRequests = []
      let auditRequests = []
      this.requests.forEach(request => {
        if (request.metadata.url === store.state.apiUrl + '/files' &&
            !!request.metadata.request.identifier) {
          fileRequests.push(request)
        } else {
          if (request.metadata.errors && typeof request.metadata.errors === 'object') {
            request.metadata.errors = Object.values(request.metadata.errors).join(', ')
          }
          auditRequests.push(request)
        }
      })

      auditRequests.forEach(request => {
        if (Array.isArray(request.metadata.request.files)) {
          request.metadata.request.files.forEach(file => {
            if (file && file.requestIdentifier) {
              let fileRequestIndex = fileRequests.findIndex(fileRequest => fileRequest.metadata.request.identifier === file.requestIdentifier)
              if (!request.subRequests) {
                request.subRequests = []
              }
              request.subRequests.push(fileRequests[fileRequestIndex])
            }
          })
        }
      })

      return auditRequests
    }
  },
  methods: {
    getActionString(request) {
      const requestBody = request.metadata
      const auditNumber = request.metadata?.request?.number
      if (requestBody.method === 'POST') {
        if (requestBody.url === store.state.apiUrl + '/files') {
          return 'Datei hochladen'
        } else if (requestBody.url === store.state.apiUrl + '/modules/audits/resources') {
          return `Prüfung erstellen${auditNumber ? ': ' + auditNumber : ''}`
        }
      } else if (requestBody.method === 'PUT') {
        return `Prüfung bearbeiten${auditNumber ? ': ' + auditNumber : ''}`
      } else if (!requestBody.url) {
        return 'Fehler: Änderung wurde nicht korrekt gespeichert'
      }
      return 'HTTP-Anfrage nach ' + requestBody.url
    },
    async getRequestsFromSW() {
      this.requests = await this.$workbox.messageSW({type: 'GET_QUEUE'})
      this.requests = this.requests.filter(request => !request.metadata.url.includes('nr-data.net') && !request.metadata.url.includes('mouseflow.com'))
    },
    getTimeString(timestamp) {
      const date = new Date(timestamp)
      return date.toLocaleString()
    },
    openModal(request) {
      this.modalData = request
      this.showModal = true
    },
    async removeRequestAndSubRequests(request) {
      this.$set(request, 'loading', true)
      if (Array.isArray(request.subRequests) && request.subRequests.length > 0) {
        for (const subRequest of request.subRequests) {
          await this.removeFromQueue(subRequest.timestamp)
        }
      }
      await this.removeFromQueue(request.timestamp)
      this.$set(request, 'loading', false)
    },
    async retry(request) {
      if (!request.loading) {
        this.$set(request, 'loading', true)
        await this.$workbox.messageSW({type: 'RETRY_ITEM', data: request.timestamp})
        await this.getRequestsFromSW()
        this.$set(request, 'loading', false)
      }
    },
    async removeFromQueue(timestamp) {
      await this.$workbox.messageSW({type: 'DELETE_ITEM', data: timestamp})
      await this.getRequestsFromSW()
    },
  },
  async created() {
    if (process.env.VUE_APP_DEBUG) {
      let request = indexedDB.open('workbox-background-sync', 2)

      request.onupgradeneeded = function (event) {
        let db = event.target.result
        db.createObjectStore('requests', {keyPath: 'id'})
      }
    }

    await this.getRequestsFromSW()
  },
  mixins: [api]
}
</script>

<style scoped>

</style>
