<template>
  <div class="inline-block relative h-2 w-2">
    <div class="spinner absolute animate-ping" :class="spinner ? spinner : 'bg-brand'">
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: ['spinner']
}
</script>

<style scoped>
  .spinner {
    transform: translateX(-.5rem);
    @apply rounded-full h-2 w-2;
    animation: load 2s ease-in-out infinite;
  }

  @keyframes load {
    25% {
      transform: translateX(-.5rem);
      @apply w-6;
    }
    50% {
      transform: translateX(.5rem);
      @apply w-2;
    }
    75% {
      transform: translateX(-.5rem);
      @apply w-6;
    }
  }
</style>
