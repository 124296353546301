<template>
  <div>
    <h1>Prüfung in Warteschlange bearbeiten</h1>

    <audit-form
      v-if="formData"
      :default-form-data="formData"
      @submit="submit"
    />
    <div v-else class="flex items-center justify-center h-20">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import AuditForm from '@/components/AuditForm'
import store from '@/store'
import api from '@/api'
import Loading from '@/components/Loading'

export default {
  name: 'QueueEdit.vue',
  components: { Loading, AuditForm },
  data: () => ({
    request: Object,
    formData: null,
    submitDisabled: false
  }),
  computed: {},
  methods: {
    async submit (data) {
      this.submitDisabled = true
      await this.$workbox.messageSW({ type: 'DELETE_ITEM', data: this.request?.timestamp })

      const requestData = {
        method: this.request.metadata.method,
        body: JSON.stringify(data),
        headers: {
          'Authorization': 'Bearer ' + store.state.user.accessToken,
          'Content-Type': 'application/json',
        },
      }
      console.error(this.request.metadata)
      const request = new Request(this.request.metadata.url, requestData)
      store.commit("addToRequestLog", {...requestData, timestamp: new Date().getTime(), url: this.request.metadata.url})

      fetch(request)
        .then(response => response.json())
        .then(response => {
          this.submitDisabled = false
          if (response.success) {
            this.$emit('alert', 'success', 'Änderungen hochgeladen')
            this.$router.push({ name: 'Queue' })
          }
        })
        .catch(async error => {
          this.submitDisabled = false
          this.$emit('alert', 'success', 'Änderung gespeichert')
          this.$router.push({ name: 'Queue' })
          console.error(error)
        })
    },
  },
  created () {
    this.$workbox.messageSW({ type: 'GET_QUEUE' }).then(requests => {
      requests.forEach(request => {
        if (
          request.timestamp &&
          request.timestamp.toString() === this.$route.params.timestamp &&
          request.metadata.request
        ) {
          this.$set(this, 'request', request)
          this.$set(this, 'formData', request.metadata.request)
        }
      })
    })
  },
  mixins: [api]
}
</script>

<style scoped>

</style>
