<template>
  <transition name="slide-in">
    <section v-if="opened"
             class="fixed inset-0 overflow-hidden"
             :class="[topClass]"
             aria-labelledby="slide-over-title"
             role="dialog"
             aria-modal="true">
      <div class="absolute inset-0 overflow-hidden">
        <div class="cloak transition-all duration-500 absolute inset-0 bg-black bg-opacity-25 transition-opacity"
             aria-hidden="true"
             @click="closeDrawer"
        ></div>

        <div class="drawer transition-all duration-500 absolute inset-y-0 left-0 pr-10 max-w-full flex max-w-md">
          <div class="relative w-screen max-w-md">
            <div class="absolute top-0 right-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
              <button @click="closeDrawer"
                      class="transition text-gray hover:text-gray-darker focus:outline-none focus:ring-2 focus:ring-white">
                <span class="sr-only">Panel schließen</span>
                <fa icon="times"></fa>
              </button>
            </div>

            <div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-auto overflow-x-hidden">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  name: 'Drawer',
  props: [
    'opened',
    'top'
  ],
  computed: {
    topClass () {
      return 'top-' + this.top
    }
  },
  methods: {
    closeDrawer () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.slide-in-enter-active,
.slide-in-leave-active {
  @apply transition-all duration-500;
}

.slide-in-enter .cloak, .slide-in-leave-to .cloak {
  @apply opacity-0;
}

.slide-in-enter .drawer, .slide-in-leave-to .drawer {
  @apply transform -translate-x-full;
}
</style>
