import Vue from 'vue'
import Vuex from 'vuex'
import {version} from '../../package.json'
import {dumpLog} from "../main";
import {db} from "../db";

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    apiUrl: process.env.VUE_APP_SYSTEM_API_URL,
    auditNumbers: [],
    online: true,
    downloadingOfflineData: false,
    createFormData: {},
    debug: process.env.VUE_APP_DEBUG,
    lastChecklistReload: null,
    modules: {
      checklists: {},
      audits: {},
      customers: {},
      equipment: {},
      schedules: {},
    },
    requestLog: [],
    user: {
      accessToken: '',
      email: '',
      id: '',
      name: '',
      module: {
        identifier: null,
        id: null,
      }
    },
    signature: null,
    version: version
  },
  getters: {
    authenticated: state => state.user.accessToken !== '',
  },
  actions: {
    async init (context) {
      const dbData = await db.store.toArray()
      if (dbData.length > 0) {
        const store = dbData[0].data
        context.commit('replace', store)
      } else {
        const localStorageData = localStorage.getItem('store')
        if (localStorageData) {
          context.commit('replace', JSON.parse(localStorageData))
        }
      }
      if (context.state.version !== version) {
        context.commit('setVersion', version)
      }
      return true
    }
  },
  mutations: {
    addToRequestLog(state, data) {
      this.commit('cleanupRequestLog')
      data.body = JSON.stringify({
        ...JSON.parse(data.body),
        files: []
      })
      delete data.headers
      state.requestLog.push(data)

      if (state.requestLog.length % 20 === 0) {
        // upload log file to server
        dumpLog()
      }
    },
    cleanupRequestLog(state) { // removes old requests from the log
      state.requestLog = state.requestLog.filter(request => {
        if (!request.timestamp) return false
        let lastWeek = new Date()
        lastWeek.setDate(lastWeek.getDate() - 7)
        return new Date(request.timestamp) > lastWeek
      })
    },
    clearAccessToken (state) {
      state.user.accessToken = ''
    },
    replace(state, store) {
      this.replaceState(Object.assign(state, store))
    },
    setAudits(state, data) {
      state.modules.audits = data
    },
    setAuditNumbers(state, data) {
      state.auditNumbers = data
    },
    updateAudits(state, data) {
      state.modules.audits = Object.assign(state.modules.audits, data)
    },
    setChecklists(state, data) {
      state.modules.checklists = data
    },
    setCreateFormData(state, data) {
      state.createFormData = data
    },
    clearCreateFormData (state) {
      state.createFormData = {}
    },
    setCustomers(state, data) {
      state.modules.customers = data
    },
    setEquipment(state, data) {
      state.modules.equipment = data
    },
    setLastChecklistReload(state, data) {
      state.lastChecklistReload = data
    },
    setSchedules(state, data) {
      state.modules.schedules = data
    },
    setUserModule(state, moduleData) {
      state.user.module = moduleData
    },
    setSignature(state, signature) {
      state.signature = signature
    },
    setOnline(state, data) {
      state.online = data
    },
    setUser(state, userData) {
      state.signature = null
      state.user = userData
    },
    setVersion(state, version) {
      state.version = version
    },
    updateAuditsShallow(state, data) {
      Object.keys(data).forEach(id => {
        if (!state.modules.audits[id]) {
          state.modules.audits[id] = {}
        }
        Object.assign((state.modules.audits[id]), data[id])
      })
    },
  },
})
