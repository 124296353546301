<template>
  <div>
    <h1>Prüfung bearbeiten</h1>

    <audit-form
      v-if="formData"
      :default-form-data="formData"
      @submit="edit"
    />
    <div v-else class="flex items-center justify-center h-20">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import AuditForm from '@/components/AuditForm'
import store from '@/store'
import api from '@/api'
import Loading from '@/components/Loading'

export default {
  name: 'Edit.vue',
  components: { Loading, AuditForm },
  data: () => ({
    formData: Object,
    submitDisabled: false
  }),
  computed: {},
  methods: {
    edit (data) {
      this.submitDisabled = true

      const identifier = Math.random().toString(36).substr(2, 9)

      const requestData = {
        method: 'PUT',
        body: JSON.stringify({ ...data, identifier: identifier }),
        headers: {
          'Authorization': 'Bearer ' + store.state.user.accessToken,
          'Content-Type': 'application/json',
        },
      }
      const request = new Request(store.state.apiUrl + '/modules/audits/resources/' + this.$route.params.audit_id, requestData)
      store.commit("addToRequestLog", {...requestData, timestamp: new Date().getTime()})

      fetch(request)
        .then(response => response.json())
        .then(response => {
          this.submitDisabled = false
          if (response.success) {
            this.$emit('alert', 'success', 'Erfolgreich gespeichert')
            this.$router.push({ name: 'Home' })
            this.updateAuditStore([response.data])
          }
        })
        .catch(async error => {
          this.submitDisabled = false

          const queue = await this.$workbox.messageSW({ type: 'GET_QUEUE' })
          if (queue.find(request => request?.metadata?.request?.identifier === identifier)) {
            this.$emit('alert', 'success', 'Änderung wurde in der Warteschlange gespeichert')
            this.updateAuditStore([data])
            this.$router.push({ name: 'Home' })
          } else {
            this.$emit('alert', 'danger', 'Änderung konnte nicht in der Warteschlange gespeichert werden. Stellen Sie eine Internetverbindung her und versuchen Sie es erneut.')
          }

          console.error(error)
        })
    },
    updateAuditStore (audits) {
      let data = {}
      audits.forEach((audit) => {
        data[audit.id] = audit
      })
      store.commit('updateAudits', data)
    }
  },
  created () {
    this.formData = store.state.modules.audits[this.$route.params.audit_id] || null
    if (!this.formData || !this.formData.checklist) {
      this.fetchResourcesDetail('audits', [{ id: this.$route.params.audit_id }])
        .then(response => {
          let audit = response[0]
          this.$set(this, 'formData', audit)
          store.commit('updateAudits', {[audit.id]: audit})
          this.$forceUpdate()
        })
    }
  },
  mixins: [api]
}
</script>

<style scoped>

</style>
