<template>
  <div>
    <h1>Prüfung erstellen</h1>

    <audit-form
        :default-form-data="createFormData"
        @change="changed"
        @submit="create"
        :submit-disabled="submitDisabled"
    ></audit-form>
  </div>
</template>

<script>
import store from '@/store'
import AuditForm from '@/components/AuditForm'
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween'
import {cloneDeep} from "lodash";

export default {
  name: 'Create',
  components: {AuditForm},
  data: function () {
    return {
      createFormData: {checklist: [], files: []},
      submitDisabled: false
    }
  },
  computed: {
    schedules() {
      return store.state.modules.schedules
    },
  },
  watch: {
    createFormData: {
      handler: () => {
      },
      deep: true
    }
  },
  methods: {
    create(data) {
      if (!data.number) {
        this.$emit('alert', 'danger', "Sie haben keine Prüfnummer angegeben.")
        return
      }
      if (Number.isInteger(data.number)) {
        data.number = data.number.toString()
      }

      const identifier = Math.random().toString(36).substring(2, 9)

      this.submitDisabled = true
      const requestData = {
        url: store.state.apiUrl + '/modules/audits/resources',
        method: 'POST',
        body: JSON.stringify({...data, identifier: identifier}),
        headers: {
          'Authorization': 'Bearer ' + store.state.user.accessToken,
          'Content-Type': 'application/json',
        }
      }
      const request = new Request(store.state.apiUrl + '/modules/audits/resources', requestData)
      store.commit("addToRequestLog", {...requestData, timestamp: new Date().getTime()})

      fetch(request)
          .then(response => response.json())
          .then(response => {
            this.submitDisabled = false
            if (response.success) {
              this.$emit('alert', 'success', 'Erfolgreich erstellt')
              this.cleanupFormData(data)
              this.$router.push({name: 'Home'})
            } else {
              this.$emit('alert', 'danger', Object.values(response.errors).join(' '))
            }
          })
          .catch(error => {
            this.submitDisabled = false
            this.$workbox.messageSW({type: 'GET_QUEUE'}).then(queue => {
              const queueElement = queue.find(request => request?.metadata?.request?.identifier === identifier)
              if (queueElement && queueElement.metadata.request.number) {
                this.$emit('alert', 'success', 'Änderung wurde in der Warteschlange gespeichert')
                this.cleanupFormData(data)
                this.$router.push({name: 'Home'})
                return
              }
              this.$emit('alert', 'danger', 'Änderung konnte nicht in der Warteschlange gespeichert werden. Stellen Sie eine Internetverbindung her und versuchen Sie es erneut.')
            })
                .catch(() => {
                  this.$emit('alert', 'danger', 'Hintergrundspeicher wurde nicht erreicht.')
                })
            console.error(error)
          })

    },
    changed(data) {
      const saveData = cloneDeep(data)
      this.cleanupFormData(saveData)
      // store.commit('setCreateFormData', saveData)
    },
    cleanupFormData(data) {
      if (data.type && data.type.id) {
        store.state.modules.checklists.forEach(checklist => {
          if (data.type.id === checklist.id && checklist.checklist) {
            data.checklist = checklist.checklist.map(step => {
              let data = {...step}
              delete data.id
              delete data.resource_id
              return data
            })
          }
        })
      }
      if (!isNaN(parseInt(data.number)) && parseInt(data.number) % 10 !== 0) {
        data.number = parseInt(data.number) + 1
      } else {
        delete data.number
      }
      delete data.inventory_number
      delete data.start_time
      delete data.end_time
      delete data.files
      delete data.next_audit
      store.commit('setCreateFormData', data)
    }
  },
  created() {
    dayjs.extend(isBetween)

    const createFormData = cloneDeep(store.state.createFormData || {})
    try {
      if (Array.isArray(this.schedules)) {
        this.schedules.forEach(schedule => {
          // If the schedule's timeframe contains today, we prefill our customer
          if (!schedule.timeframe || !schedule.timeframe.start) return
          if (dayjs().isBetween(schedule.timeframe.start, schedule.timeframe.end, 'day', '[]')) {
            if (!createFormData.schedule) {
              createFormData.schedule = schedule
            }
            if (!createFormData.customer && schedule.customer) {
              createFormData.customer = {
                id: schedule.customer.id,
                module_id: schedule.customer.module_id
              }
            }
          }
        })
      }
    } catch (e) {
      alert(e)
    }

    createFormData.date = new Date().toISOString().substring(0, 10)

    if (createFormData.start_time &&
        dayjs().diff(createFormData.start_time, "h") > 8
    ) {
      this.cleanupFormData(createFormData)
    }

    delete createFormData.files

    if (createFormData.checklist) {
      createFormData.checklist = createFormData.checklist.map((step) => {
        step.check = null
        step.result = null
        step.cost_plus = null
        if (Array.isArray(step.file)) {
          step.file.forEach(async (file, fileIndex) => {
            step.file[fileIndex].__url = null
          })
        }
        return step
      })
    }

    this.$set(this, 'createFormData', createFormData)
  },
}
</script>
