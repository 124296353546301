import {
  TAlert,
  TButton,
  TCard,
  TInput,
  TInputGroup,
  TSelect,
  TRichSelect,
  TTextarea,
  TCheckbox,
  TPagination, TModal,
} from 'vue-tailwind/dist/components'

export default {
  't-input': {
    component: TInput,
    props: {
      fixedClasses: 'px-3 py-2 transition block rounded-none flex-1 w-full shadow focus:ring-2 focus:ring-opacity-50',
      classes: 'border-gray focus:ring-brand focus:border-brand',
      variants: {
        danger: 'border-danger bg-danger-light placeholder-danger',
        loading: 'bg-gray-lighter',
        success: 'border-success bg-success-light placeholder-success'
      }
    }
  },
  't-checkbox': {
    component: TCheckbox,
    props: {
      fixedClasses: 'w-12 h-12 transition duration-100 border-gray shadow cursor-pointer focus:ring-2 focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-brand focus:ring-brand focus:border-brand',
    }
  },
  't-textarea': {
    component: TTextarea,
    props: {
      classes: 'transition duration-300 focus:ring-brand focus:border-brand flex-1 block w-full sm:text-sm border-gray-300'
    }
  },
  't-input-group': {
    component: TInputGroup,
    props: {
      classes: {
        wrapper: 'mb-4 relative'
      }
    }
  },
  't-button': {
    component: TButton,
    props: {
      fixedClasses: 'transition duration-300 py-2 px-4 text-sm border shadow hover:shadow-md focus:outline-none focus:ring-2 disabled:opacity-60',
      classes: 'bg-brand border-brand text-white hover:bg-brand-dark hover:border-brand-dark focus:ring-brand-light',
      variants: {
        secondary: 'bg-white border-gray text-black hover:bg-gray-lightest focus:ring-gray-lightest',
        danger: 'bg-danger text-white hover:bg-danger-dark focus:ring-danger-dark',
      }
    }
  },
  't-alert': {
    component: TAlert,
    props: {
      fixedClasses: {
        wrapper: 'relative flex items-center p-2 border-l-4 shadow',
        body: 'flex-grow',
        close: 'absolute flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-500',
        closeIcon: 'fill-current h-4 w-4'
      },
      classes: {
        wrapper: 'bg-white text-black',
        body: 'text-black',
        close: 'text-black'
      },
      variants: {
        danger: {
          wrapper: 'bg-danger-light border-danger',
          body: 'text-danger-dark',
          close: 'text-danger-dark'
        },
        info: {
          wrapper: 'bg-info-light border-info',
          body: 'text-info-dark',
          close: 'text-info-dark'
        },
        warning: {
          wrapper: 'bg-warning-light border-warning',
          body: 'text-warning-dark',
          close: 'text-warning-dark'
        },
        success: {
          wrapper: 'bg-success-light border-success',
          body: 'text-success-dark',
          close: 'text-success-dark'
        }
      }
    }
  },
  't-card': {
    component: TCard,
    props: {
      fixedClasses: {
        wrapper: 'mb-4 shadow border-t-2 border-brand',
        body: 'text-black border-t-2 border-gray-lighter',
        header: 'p-4 text-lg leading-6 font-medium black',
        footer: 'text-lg leading-6 font-medium'
      },
      classes: {}
    }
  },
  't-select': {
    component: TSelect,
    props: {
      fixedClasses: 'w-100 relative bg-brand',
      classes: 'px-3 py-2 text-black transition ease-in-out border border-brand shadow focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed'
    }
  },
  't-rich-select': {
    component: TRichSelect,
    props: {
      classes: {
        selectButton: 'border px-3 py-2 text-black transition border-gray shadow focus:border-brand focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed',
        dropdown: 'border bg-white border-gray p-2',
        optionContent: 'flex justify-between py-2 px-3 text-black text-sm hover:bg-brand-light',
        selectedHighlightedOption: 'bg-brand-light',
        searchBox: 'px-3 py-2 bg-gray-lightest text-sm border border-gray focus:outline-none',
      },
      variants: {
        up: {
          dropdown: 'border bg-white border-gray p-2 bottom-full',
        }
      }
    }
  },
  't-pagination': {
    component: TPagination,
    props: {
      fixedClasses: {
        wrapper: 'table border-collapse text-center mx-auto shadow-sm',
        element: 'w-8 h-8 border table-cell',
        activeElement: 'w-8 h-8 border table-cell',
        disabledElement: 'w-8 h-8 border table-cell',
        ellipsisElement: 'w-8 h-8 border hidden md:table-cell',
        activeButton: 'w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:outline-none focus:ring-opacity-50',
        disabledButton: 'opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out',
        button: ' w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:outline-none focus:ring-opacity-50',
        ellipsis: ''
      },
      classes: {
        wrapper: 'bg-white',
        element: 'border-gray-lighter hover:border-brand-light',
        activeElement: 'border-gray-lighter border-brand hover:border-brand',
        disabledElement: 'border-gray-lighter',
        ellipsisElement: 'border-gray-lighter',
        activeButton: 'bg-brand text-white hover:bg-brand-dark focus:ring-brand',
        disabledButton: '',
        button: 'hover:bg-brand-light focus:ring-brand',
      },
    }
  },
  't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay: 'z-40 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50',
        wrapper: 'relative mx-auto z-50 max-w-lg px-3 py-12',
        modal: 'overflow-visible relative shadow-xl',
        body: 'p-3',
        header: 'border-b p-3',
        footer: 'p-3',
        close: 'flex items-center justify-center absolute right-0 top-0 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:outline-none focus:ring-opacity-50'
      },
      classes: {
        overlay: 'bg-black',
        wrapper: '',
        modal: 'bg-white shadow',
        body: 'p-4',
        header: 'border-gray',
        footer: 'bg-gray',
        close: 'bg-gray-lighter text-gray-darker hover:bg-gray-light focus:ring-brand m-0',
        closeIcon: 'fill-current h-4 w-4',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
      }
    },
  }
}
