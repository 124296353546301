import store from '@/store'

export default {
  methods: {
    fetchResourcesByModuleIdentifier (identifier, queryParams) {
      let url = store.state.apiUrl + '/modules/' + identifier + '/resources'
      if (queryParams) {
        url += '?' + new URLSearchParams(queryParams)
      }

      return fetch(url, {
        headers: {
          'Authorization': 'Bearer ' + store.state.user.accessToken,
        },
      })
        .then(response => response.json())
    },
    fetchResourcesDetail (moduleIdentifier, resourceData = []) {
      return new Promise(resolve => {
        let data = resourceData
        if (data.length === 0) {
          data = store.state.modules[moduleIdentifier].data
        }
        let promises = []
        data.forEach((resource) => {
          let url = store.state.apiUrl + '/modules/' + moduleIdentifier + '/resources/' + resource.id
          promises.push(
            fetch(url, {
              headers: {
                'Authorization': 'Bearer ' + store.state.user.accessToken,
              },
            })
              .then(response => response.json())
              .then(response => {
                return response
              })
          )
        })
        Promise.all(promises).then(values => {
          resolve(values)
        })
      })
    },
    cacheFilesFromChecklistResources (resourcesWithChecklist = []) {
      // request all files from checklist so they can be cached by workbox
      // both the modules "audits" and "checklists" have resources with checklists
      return new Promise(resolve => {
        let promises = []

        resourcesWithChecklist.forEach(resource => {
          if (!resource.checklist) return

          resource.checklist.forEach((step) => {
            if (Array.isArray(step.file) && step.file.length > 0) {
              step.file.forEach(file => {
                let url = store.state.apiUrl + '/files/' + file.id
                promises.push(
                  fetch(url, {
                    headers: {
                      'Authorization': 'Bearer ' + store.state.user.accessToken,
                    },
                  })
                )
              })
            }
          })
        })
        Promise.all(promises).then(() => resolve())
      })
    },
    getFileUrl (file) {
      // fetches file url and creates object url
      return fetch(store.state.apiUrl + '/files/' + file.id, {
          headers: {
            'Authorization': 'Bearer ' + store.state.user.accessToken,
          }
        }
      )
        .then(response => response.blob())
        .then(response => {
          return URL.createObjectURL(response)
        })
    }
  }
}
