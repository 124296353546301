import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTailwind from 'vue-tailwind'
import './index.css'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faArrowAltCircleLeft,
    faCalendar,
    faCalendarAlt,
    faEdit,
    faPlusSquare,
    faSave,
    faTrashAlt,
    faUser
} from '@fortawesome/free-regular-svg-icons'
import {
    faArrowLeft,
    faArrowRight,
    faBarcode,
    faBars,
    faCheck,
    faClipboardList,
    faCloudDownloadAlt,
    faCode,
    faDownload,
    faFileInvoice,
    faHistory,
    faMagic,
    faPaperclip,
    faQuestionCircle,
    faRedo,
    faSignOutAlt,
    faSpinner,
    faSync,
    faTimes,
    faTimesCircle,
    faToolbox,
    faTrashRestoreAlt,
    faQuestion,
} from '@fortawesome/free-solid-svg-icons'
import VueTailwindSettings from './vue-tailwind-settings'
import wb from './registerServiceWorker'
import VueSignaturePad from 'vue-signature-pad'
import {db} from "./db"
import { debounce } from "lodash";
import {liveQuery} from "dexie";


const icons = [
    faCalendar,
    faUser,
    faPlusSquare,
    faArrowAltCircleLeft,
    faSpinner,
    faClipboardList,
    faArrowRight,
    faArrowLeft,
    faBars,
    faCheck,
    faRedo,
    faPaperclip,
    faTimes,
    faSave,
    faSignOutAlt,
    faHistory,
    faDownload,
    faEdit,
    faTrashAlt,
    faTrashRestoreAlt,
    faCloudDownloadAlt,
    faFileInvoice,
    faCalendarAlt,
    faTimesCircle,
    faToolbox,
    faCode,
    faQuestionCircle,
    faSync,
    faBarcode,
    faMagic,
    faQuestion
]

library.add(...icons)

Vue.component('fa', FontAwesomeIcon)

Vue.prototype.$workbox = wb

Vue.prototype.store = store

Vue.config.productionTip = false
Vue.config.performance = true

const settings = VueTailwindSettings

Vue.use(VueTailwind, settings)

Vue.use(VueSignaturePad)

store.dispatch('init')
    .then(() => {
        window.app = new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount('#app')
    })

let curStoreId = 0

const subscription = liveQuery(() => db.store.toArray()).subscribe({
    next: result => {
        if (curStoreId !== 0 && result.length === 1) {
            if (curStoreId !== result[0].id) {
                store.commit('replace', result[0].data)
            }
        }
    },
    error: () => subscription.unsubscribe()
})

store.subscribe(
    debounce(async (mutation, state) => {
        try {
            await db.transaction("rw", db.store, async () => {
                const entries = await db.store.toArray()
                let newEntry = {
                    data: state
                }
                if (entries.length > 0) {
                    if (mutation.type === "replace") return
                    newEntry = {
                        data: Object.assign(entries[0].data, state)
                    }
                }
                curStoreId = await db.store.add(newEntry)
                await db.store.where("id").notEqual(curStoreId).delete()
            })
            if (mutation.type === "setVersion") {
                navigator.serviceWorker.getRegistration().then(function (reg) {
                    if (reg) {
                        reg.unregister()
                            .then(() => window.location = '/')
                    } else {
                        window.location = '/'
                    }
                })
            }
            return true
        } catch (error) {
            alert("Log-Speicher ausgelastet. Aktueller Log-Speicher wird in Datei ausgelagert.")
            dumpLog(state.requestLog, true)
            state.requestLog = []
            localStorage.setItem('store', JSON.stringify(state))
            console.error(error)
        }
    }, 300)
)

export async function dumpLog(log = null, download = false, upload = true) {
    if (!log) {
        log = store.state.requestLog
    }
    let filename = store.state.user.id + '_' + (new Date().getTime()) + '.json'
    if (download) {
        downloadFile(filename, JSON.stringify(log))
    }
    if (!upload) return
    const data = new FormData()
    data.append('file', new File([JSON.stringify(log)], filename, {
        type: "application/json",
    }))
    data.append('module', 'files')
    data.append('path', '/Prüfapp-Logs')
    try {
        await fetch(store.state.apiUrl + '/files', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + store.state.user.accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: "Prüfapp-Logs", module: "files", path: "/", directory: true
            })
        })
    } catch (e) {
        console.error(e)
    }

    return fetch(store.state.apiUrl + '/files', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + store.state.user.accessToken,
        },
        body: data
    })
}

function downloadFile(filename, data) {
    let element = document.createElement('a')
    element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(data))
    element.setAttribute('download', filename)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}
