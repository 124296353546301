<template>
  <div class="md:w-3/4 mx-auto">
    <div class="flex justify-between mb-4">
      <h1 class="">Hilfe</h1>
    </div>
    <div class="mb-4">
      <h2>Inhalt</h2>
      <ol class="list-decimal">
        <li>
          <a href="#offline">
            Offline-Modus
          </a>
          <ol style="list-style-type: lower-roman">
            <li>
              <a href="#queue">
                Warteschlange
              </a>
            </li>
          </ol>
        </li>
        <li>
          <a href="#log">
            Log
          </a>
        </li>
        <li>
          <a href="#tips">
            Tipps
          </a>
        </li>
      </ol>
    </div>
    <div id="offline" class="mb-4">
      <h2>Offline-Modus</h2>
      <p class="mb-4">
        Im Offline-Modus haben Sie die Möglichkeit, Prüfungen auch ohne bestehende Internetverbindung zu erstellen und
        zu bearbeiten.<br>
        Neue Prüfungen erstellen Sie weiterhin über den "Neue Prüfung erstellen"-Knopf auf der Startseite. Bearbeiten
        können Sie jedoch nur alle Prüfungen, die Sie sich zuvor über den "Download"-Knopf in der Auflistung der
        Prüfungen heruntergeladen haben.<br>
        Für Erstellung und Bearbeitung der Prüfungen offline werden zudem die Prüflisten, Planungen und Kundendaten aus
        dem Kiwis-System benötigt. Stellen Sie also vor einem Offline-Prüfgang sicher, dass diese Daten heruntergeladen
        wurden, indem Sie auf die Reiter im Menü drücken oder die Seite neu laden.<br>
        Ihre Änderungen werden in einer Warteschlange gespeichert, auf die Sie über das Menü zugreifen
        können.
      </p>
      <h3 id="queue" class="mb-4">Warteschlange</h3>
      <img class="float-right max-w-full w-52 mx-auto my-2 ml-2" src="img/help/queue_symbol.png">
      <p class="mb-4">
        Die Warteschlange speichert Ihre ausgeführten Aktionen im Offline-Modus, die eine Internetverbindung benötigen.
        Sie können den aktuellen Status der Warteschlange in der Menüleiste oben rechts überprüfen.
        Die Farbe des Symbols gibt Ihnen einen Hinweis auf den aktuellen Status der Warteschlange:
      </p>
      <ul class="">
        <li>
          <b>Grün:</b> Die Warteschlange ist einsatzbereit.
        </li>
        <li>
          <b>Gelb:</b> Die Warteschlange ist aktuell nicht einsatzbereit. In den meisten Fällen tritt dies direkt nach
          dem ersten Betreten der Seite auf und lässt sich meist durch neu laden beheben.
        </li>
        <li>
          <b>Rot:</b> Die Warteschlange steht auf diesem Gerät/diesem Browser nicht zur Verfügung. Wenn offline
          geprüft werden muss, muss hier mit dem Log gearbeitet werden.
        </li>
      </ul>
      <p class="mb-4 clear-both">
        Auf der Seite <a href="/queue">Warteschlange</a> können Sie die Einträge der Warteschlange bearbeiten. Dort
        stehen Ihnen folgende Optionen für die Einträge zur Verfügung:
        <br>
        <img class="float-left max-w-full w-52 mx-auto my-4 mr-6" src="img/help/queue.png">
      </p>
      <ul class="mt-4">
        <li style="color: limegreen">
          <b>Eintrag bearbeiten</b>
        </li>
        <li style="color: blue">
          <b>Aktion erneut versuchen</b> (Dies wird automatisch ausgeführt, sobald wieder eine Internetverbindung
          besteht.)
        </li>
        <li style="color: orange">
          <b>Eintrag löschen</b>
        </li>
      </ul>
    </div>
    <div id="log" class="mb-4 clear-both">
      <h2>Log</h2>
      <p class="mb-4">
        In dem <a href="/log">Log</a> werden alle Prüfungserstellungen und -bearbeitungen gespeichert. Es dient als
        Sicherheitsnetz, damit im Fehlerfall (z.B. Problem bei der Warteschlange) keine Daten verloren gehen.
        Die Einträge im Log stehen mindestens 7 Tage zur Verfügung. Einträge älter als 7 Tage werden entfernt, sobald
        ein neuer Eintrag gespeichert wird.<br>
        Der Speicherplatz des Logs ist begrenzt und wird automatisch vom Browser anhand des verfügbaren Gerätespeichers
        berechnet. Sobald erkannt wird, dass der Speicher voll ist, werden Sie von der Prüfapp benachrichtigt und es
        wird automatisch eine Datei des Typs <code>.json</code> erstellt. Diese wird sowohl bei Ihnen auf dem Gerät
        gespeichert, als auch an das Kiwis-System gesendet. Wenn Sie sich im Offline-Modus befinden, findet das Hochladen der
        Datei über die Warteschlange statt. So wird sichergestellt, dass keine Prüfdaten verloren gehen. Eine Datei wird
        außerdem bei jedem 20. Log-Eintrag automatisch erstellt und an das Kiwis-System geschickt.<br>
        Im Gegensatz zur Warteschlange werden im Log keine Dateien gespeichert. Falls Sie also eine Prüfung aus dem Log
        wiederherstellen möchten, müssen Sie hinzugefügte Bilder im Formular erneut hinzufügen.
      </p>
    </div>
    <div id="tips" class="mb-4 clear-both">
      <h2>Weitere Tipps</h2>
      <p class="mb-4">
        Allgemeine Tipps zur Verwendung der App und zur Vermeidung von Problemen:
      </p>
      <ul>
        <li>
          <img class="float-right max-w-full w-40 mx-auto" src="img/help/version.png">
          Die App aktualisiert sich von selbst, allerdings ist ab und zu mehrfaches neu laden nötig, damit die
          Aktualisierung durchgeführt wird. Sie können die aktuelle Version oben in der Menüleiste neben den Statusicons
          oder unten im Menü einsehen. Die Versionsnummer leuchtet gelb auf, wenn eine neue Version zur Verfügung steht.
        </li>
        <li class="clear-both">
          Wenn Sie Bilder zur Prüfung hinzufügen, schießen Sie die Fotos in der Kamera-App Ihres
          Geräts und fügen Sie sie danach zur Prüfung hinzu. Dadurch haben Sie die Bilder weiterhin auf dem Gerät nach
          Abschluss der Prüfung und können diese
          im Fehlerfall im Nachhinein zur Prüfung hinzufügen.
        </li>
        <li>
          Falls Sie an einem Ort mit schwacher/instabiler Internetverbindung Prüfungen durchführen ist es sicherer, die
          Internetverbindung Ihres Gerätes vollständig zu deaktivieren. Dadurch werden Ihre durchgeführten
          Prüfungen zunächst in der Warteschlange gespeichert und können später bei stabiler Verbindung hochgeladen
          werden.
        </li>
        <li>
          Wenn Sie Prüfungen aus dem Log wiederherstellen möchten: entfernen Sie vorher zuvor
          hochgeladene Dateien aus der Prüfung, da das Hochladen sonst fehlschlägt. Dies muss jedoch nur für Prüfungen
          getan werden,
          die in einer Prüfapp-Version &#60;0.7 erstellt wurden.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Help.vue',
  data: function () {
    return {}
  },
  computed: {},
  methods: {},
  async created() {

  },
}
</script>

<style scoped>
h2 {
  @apply text-xl py-2 mb-4 border-b border-gray-lighter font-bold
}

h3 {
  @apply text-lg
}

a {
  @apply text-brand
}

ul {
  @apply list-disc
}

ul, ol {
  @apply px-4
}

li {
  @apply mb-2
}
</style>
