import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Create from '@/views/Create'
import Edit from '@/views/Edit'
import Queue from '@/views/Queue'
import QueueEdit from '@/views/QueueEdit'
import Log from '@/views/Log'
import LogEdit from '@/views/LogEdit'
import Help from "../views/Help";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/create',
    name: 'Erstellen',
    component: Create
  },
  {
    path: '/edit/:audit_id',
    name: 'Bearbeiten',
    component: Edit
  },
  {
    path: '/queue',
    name: 'Queue',
    component: Queue
  },
  {
    path: '/queue/:timestamp',
    name: 'Warteschlange - Bearbeiten',
    component: QueueEdit
  },
  {
    path: '/log',
    name: 'Log',
    component: Log
  },
  {
    path: '/log/:index',
    name: 'Log - Wiederherstellen',
    component: LogEdit
  },
  {
    path: '/help',
    name: 'Hilfe',
    component: Help
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
