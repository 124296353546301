<template>
  <div class="home">
    <div class="flex flex-wrap lg:flex-nowrap mb-4 text-right">
      <div class="w-full lg:w-1/2">
        <t-input
          v-model="search"
          placeholder="Suchen"
        />
      </div>
      <div class="w-full flex flex-wrap sm:flex-nowrap justify-between lg:justify-end mt-4 lg:mt-0 lg:w-1/2">
        <t-button class="mr-4 mb-4 sm:mb-0" variant="secondary" @click="exportAuditOverview" v-if="online">
          <fa icon="file-invoice"></fa>
          Übersicht geprüfter Arbeitsmittel
        </t-button>
        <div v-else></div>
        <router-link to="/create" class="w-full sm:w-auto">
          <t-button class="h-full" :disabled="!hasAuditData">
            <span v-if="isLoading">
              <Loading spinner="bg-white"></Loading>
              &nbsp;
            </span>
            <fa :icon="['far', 'plus-square']" v-else></fa>
            Neue Prüfung erstellen
          </t-button>
        </router-link>
      </div>
    </div>
    <div v-if="!audits" class="w-full h-full flex flex-col justify-center items-center">
      Prüfungen werden geladen...
      <Loading></Loading>
    </div>
    <div v-else-if="audits.length > 0">
      <t-pagination
        v-model="currentPage"
        class="mb-4"
        :total-items="total"
        @change="fetchAudits"
      />
      <t-card v-for="(audit, key) in audits" :key="key" :class="{'opacity-70': loading}">
        <div slot="header" class="flex justify-between">
          <div>
            <h1>Prüfung Nr. {{ audit.number }}</h1>
            <div class="max-w-2xl text-sm text-gray-darkest">
              <p class="mt-1">
                <span v-if="audit.customer">
                  <fa :icon="['far', 'user']"></fa>
                  {{ audit.customer.brezel_name }} <span class="mx-2 hidden sm:inline">|</span><br
                  class="inline sm:hidden">
                </span>
                <span>
                  <fa :icon="['far', 'calendar']"></fa>
                  {{ audit.date }}
                </span>
              </p>
              <p class="mt-1">
                <span v-if="audit.audit_ok" class="text-success-dark mt-1">
                  Prüfung ok
                </span>
              </p>
            </div>
          </div>
          <div class="flex flex-col justify-evenly">
            <router-link :to="'/edit/' + audit.id">
              <t-button style="padding: .25rem .5rem">
                <fa :icon="['far', 'edit']" class="mr-0"></fa>
              </t-button>
            </router-link>
            <div v-if="online" class="border-t border-gray-lighter my-2 w-3/4 mx-auto"></div>
            <t-button v-if="online" variant="secondary" style="padding: .25rem .5rem" @click="fetchAuditDetail(key)">
              <Loading v-if="!!audit.loading"></Loading>
              <fa v-else-if="downloaded(audit.id)" class="mr-0" icon="check"></fa>
              <fa icon="download" class="mr-0" v-else></fa>
            </t-button>
          </div>
        </div>
        <dl class="w-full">
          <div class="bg-gray-lightest px-4 p-2">
            <dt class="text-gray-dark">
              Inventarnummer
            </dt>
            <dd>
              {{ audit.inventory_number }}
            </dd>
          </div>
          <div class="px-4 p-2">
            <dt class="text-gray-dark">
              Standort
            </dt>
            <dd>
              {{ audit.location }}
            </dd>
          </div>
          <div class="bg-gray-lightest px-4 p-2">
            <dt class="text-gray-dark">
              Prüfungsart
            </dt>
            <dd v-if="audit.type">
              {{ audit.type.brezel_name }}
            </dd>
          </div>
        </dl>
      </t-card>
      <t-pagination
        v-model="currentPage"
        class="mb-4"
        :total-items="total"
        @change="fetchAudits"
      />
    </div>
    <div v-else-if="audits.length === 0" class="w-full h-full flex flex-col justify-center items-center">
      <h2>Keine Prüfungen gefunden</h2>
    </div>

    <t-modal v-model="showModal">
      <t-input-group
        label="Kunde"
      >
        <t-rich-select
          :options="customerSelectOptions"
          searchBoxPlaceholder="Suchen..."
          noResultsText="Keine Optionen verfügbar"
          maxHeight="400"
          :value="customerValue"
          @change="value => $set(this.modalData, 'customer', JSON.parse(value))"
        >
        </t-rich-select>
      </t-input-group>

      <t-input-group label="Zeitraum">
        <div class="flex items-center justify-center">
          <t-input
            v-model="modalData.timeframe.start"
            type="date"
          />
          <span class="mx-2">bis</span>
          <t-input
            v-model="modalData.timeframe.end"
            type="date"
          />
        </div>
      </t-input-group>

      <div class="text-center">
        <t-button class="my-4 w-full"
                  @click="submitModal"
        >
          Abschicken
        </t-button>
      </div>
      <div class="my-4 text-center text-danger-dark">
        <Loading v-if="modalLoading"/>
        {{ modalStatus }}
      </div>
    </t-modal>
  </div>
</template>

<script>
import store from '@/store'
import api from '@/api'
import Loading from '@/components/Loading'

export default {
  name: 'Home',
  components: { Loading },
  data: () => ({
    audits: null,
    totalRows: 0,
    search: '',
    perPage: 0,
    currentPage: 1,
    total: 0,
    loading: false,
    loadedFromStore: false,
    showModal: false,
    modalStatus: '',
    modalLoading: false,
    modalData: {
      customer: {},
      timeframe: {
        end: '',
        start: '',
      },
    },
    checkpointId: null,
  }),
  computed: {
    ready () {
      return !this.isLoading && this.hasAuditData
    },
    isLoading () {
      return store.state.downloadingOfflineData
    },
    hasAuditData () {
      return Object.keys(store.state.modules.checklists || {}).length > 0 &&
          Object.keys(store.state.modules.customers || {}).length > 0 &&
          !!store.state.modules.checklists[Object.keys(store.state.modules.checklists)[0]].checklist
    },
    customers () {
      return store.state.modules.customers
    },
    customerValue () {
      const customer = this.modalData.customer
      return customer ? JSON.stringify({ id: customer.id, module_id: customer.module_id }) : null
    },
    customerSelectOptions () {
      if (!Array.isArray(this.customers)) return
      return this.customers.map(item => ({
        id: JSON.stringify({ id: item.id, module_id: item.module_id }),
        label: item.brezel_name,
      }))
    },
    online () {
      return store.state.online
    },
  },
  methods: {
    downloaded (id) {
      return store.state.modules.audits[id] && store.state.modules.audits[id].checklist
    },
    exportAuditOverview () {
      fetch(store.state.apiUrl + '/webhook/ExportAuditOverviewPDF/audits', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + store.state.user.accessToken,
        }
      })
        .then(response => response.json())
        .then(response => {
          this.checkpointId = response?.options?.on_confirm?.checkpoint?.id
          this.showModal = true
        })
    },
    submitModal () {
      const formData = {
        state: 'confirm',
        input: this.modalData
      }
      this.modalStatus = ''
      this.modalLoading = true

      fetch(store.state.apiUrl + '/webhook/checkpoint/' + this.checkpointId, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + store.state.user.accessToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      })
        .then(response => {
          if (response.headers.get('Content-Type') !== 'application/json') {
            return response.blob()
          }
          return response.json()
        })
        .then(response => {
          if (response instanceof Blob) {
            const url = URL.createObjectURL(response)
            window.open(url)
            this.showModal = false
            this.checkpointId = null
            return
          }
          if (response.options && response.options.type === 'warning') {
            this.modalLoading = false
            this.modalStatus = 'Es wurden keine Leistungsnachweise gefunden'
            this.exportAuditOverview()
          }
        })
        .catch(error => {
          this.modalLoading = false
          this.modalStatus = 'Dokument konnte aufgrund eines Fehlers nicht erstellt werden'
          this.exportAuditOverview()
          console.error(error)
        })
    },
    fetchAudits () {
      this.loading = true
      let queryParams = {
        page: this.currentPage,
      }
      if (this.search) {
        if (isNaN(this.search)) {
          queryParams.filters = JSON.stringify({
            'customer.brezel_name': [this.search],
          })
        } else {
          queryParams.filters = JSON.stringify({
            number: [this.search],
          })
        }
      }
      return this.fetchResourcesByModuleIdentifier('audits', queryParams)
        .then(response => {
          this.audits = response.data
          this.total = response.total
          this.perPage = response.per_page
          this.totalRows = response.last_page
          this.loading = false
        })
      .catch(error => {
        this.loading = false
        this.audits = []
        console.error(error)
      })
    },
    fetchAuditDetail (key) {
      let audit = this.audits[key]
      this.$set(this.audits[key], 'loading', true)
      this.fetchResourcesDetail('audits', [audit])
        .then(async response => {
          let audit = response[0]
          this.$set(this.audits, key, audit)
          this.updateAuditStore(response)
          await this.cacheFilesFromChecklistResources(response)
          audit.loading = false
        })
    },
    initAudits () {
      // reverse object from store so the audits are shown in descending order
      let auditStore = store.state.modules.audits
      const auditIds = Object.keys(auditStore).reverse()
      this.audits = auditIds.map(id => auditStore[id])
    },
    searchAudits () {
      if (this.online) {
        this.fetchAudits()
      } else {
        this.initAudits()
        if (this.search) {
          this.audits = this.audits.filter(audit => {
            if (
              audit &&
              audit.number.includes(this.search) ||
              audit?.customer?.brezel_name.includes(this.search)
            ) return true
          })
        }
      }
    },
    updateAuditStore (audits) {
      let data = {}
      audits.forEach((audit) => {
        data[audit.id] = audit
      })
      store.commit('updateAudits', data)
    }
  },
  created () {
    if (this.online) {
      this.fetchAudits()
    } else {
      this.initAudits()
    }
  },
  watch: {
    online () {
      if (!this.audits || this.audits.length === 0) {
        this.fetchAudits()
      }
    },
    search () {
      this.searchAudits()
    },
  },
  mixins: [api]
}
</script>
